@font-face {
  font-family: 'audimatbold_italic';
  src: url('webfonts/audimat-bolditalic-webfont-webfont.woff2') format('woff2'),
       url('webfonts/audimat-bolditalic-webfont-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'audimatitalic';
  src: url('webfonts/audimat-italic-webfont-webfont.woff2') format('woff2'),
       url('webfonts/audimat-italic-webfont-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'audimatbold';
  src: url('webfonts/audimat-bold-webfont.woff2') format('woff2'),
       url('webfonts/audimat-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'audimatregular';
  src: url('webfonts/audimat-regular-webfont.woff2') format('woff2'),
       url('webfonts/audimat-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

  @font-face{
          font-family:'Melior W01';
      src:url('webfonts/842f4627-b507-42b5-ac28-621e0c3ad744.woff2') format('woff2'),url('webfonts/5c4a77e5-17ac-4267-b0cb-da7f425404a3.woff') format('woff');
  }
  @font-face{
      font-family:'Melior W01 Italic';
      src:url('webfonts/1672ccb1-e31e-4ad3-89a5-4099212791b8.woff2') format('woff2'),url('webfonts/37998ce6-1b03-43d7-a431-6782ad1e5635.woff') format('woff');
  }
  @font-face{
      font-family:'Melior W01 Bold';
      src:url('webfonts/7b79c36a-b353-4888-b02a-bc605c7f07c1.woff2') format('woff2'),url('webfonts/a15a78d2-b1cf-422f-8294-3e7a45d146ed.woff') format('woff');
  }
  @font-face{
      font-family:'Melior W01 Bold Italic';
      src:url('webfonts/1f5b43c2-a98b-40f8-aaf8-8c64b56d5652.woff2') format('woff2'),url('webfonts/18493e4e-551b-4c5f-a350-8b17d3e16dc0.woff') format('woff');
  }




.App {
  text-align: center;
  font-family: Melior W01;
}

.App {
  background-color: #940C79;
  min-height: calc(100vh - 160px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  font-family: Melior W01;
}

header {
  background: #fff;
  background: linear-gradient(0deg,#888888 0%,#f2f2f3 4%,#FFFFFF 90%);
  width: 100%;
  top: 0;
  padding: 20px 0;
  text-align:center;
}
.logo{
  height: 80px;
}

footer {
  height: 40px;
  background: #323333;
  text-align: center;
}

footer nav{
  
}
footer nav ul{
  list-style: none;
  margin: 0;
  padding: 0;
}
footer nav ul li{
  display: inline-block;
  
  font-family: Melior W01;
  color: rgba(255, 255, 255, 0.8);
}
footer nav ul li:after{
  content: '|';
}

footer nav ul li:last-child:after{
  content: '';
}

footer nav ul li a{
  text-decoration: none;
  color: rgba(255, 255, 255, 0.8);
  padding: 4px 5px;
  border-radius: 5px;
  line-height: 40px;
  font-size: 1.1rem;
}

footer:hover nav ul li a{
  color: rgba(255, 255, 255, 1);
  text-decoration:underline;
}

footer:hover nav ul li a:hover{
  text-decoration:none;
}

input{
  height: 60px;
  border: none;
  outline: none;
  min-width: 300px;
  font-size: 30px;
  padding: 0 1rem;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  font-family: audimatregular;
  @media(max-width: 700px) {
    min-width: calc(80% - 2rem);
    border-radius: 10px;
  }
}
button{
  font-size: 30px;
  border: solid 1px #000;
  background: linear-gradient(0deg,#000000 0%,#333333 100%);
  color: #fff;
  padding: 0px 20px;
  height: 60px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  font-family: audimatregular;
  @media(max-width: 700px) {
    border-radius: 10px;
    min-width: 80%;
    margin-top: 1rem;
  }
}

button:hover, button.s:hover{
  color: #fff;
  background: #000;
}

button:focus, button:active, button.s:focus, button.s:active{
  color: #fff;
  background: linear-gradient(0deg,#333 0%,#000 100%);
}

button.s {
  font-size: 1rem;
  border: solid 1px #000;
  background: linear-gradient(0deg,#000000 0%,#333333 100%);
  color: #fff;
  padding: 0px 10px;
  height: 30px;
  border-radius: 5px;
  margin-top: 4rem;
  @media(max-width: 700px) {
    border-radius: 10px;
    min-width: 10px;
    margin-top: 4rem;
  }
}

button.s.copy {
  margin-top: 5px;
  display:block;
}

h1{
  font-family: audimatbold;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ergebnis{
  max-width: 900px;
  font-size: 25px;
}

dl{
  max-width: 80%;
  margin: 0 auto;
}
dl dt{
  font-family: audimatregular;
  float: left;
  margin-bottom: 20px;
}
dl dd{
  margin-left: 150px;
  text-align: left;
  margin-bottom: 20px;
}

.small {
  font-size:1rem;
  line-height: 120%;
  padding: 1rem;
  background-color: rgba(255, 255, 255, 0.1);
  margin: 2rem auto;
  max-width: calc(80% - 2rem);
}

p{
  min-width: 600px;
  max-width: 80%;
  margin: 1em auto;
  line-height: 120%;
  @media(max-width: 700px) {
    min-width: 100px;
  }
}